import React from 'react';

import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';

import logo from './assets/logo.jpeg';
import { Peluches } from "./components/Peluches";
import { Tenis } from "./components/Tenis";
import { Home } from "./components/Home";
import './App.css';

function App() {

  return (
    <>
      <div className="alert alert-primary first-alert" role="alert">
        <strong>Bienvenido a lasir!</strong> Este es nuestro catalogo web que esta en fase de prueba.
      </div>
      <Router>
      <nav className="navbar navbar-dark bg-primary navbar-expand-lg">
        <a className="navbar-brand" to="/">
          <div className="container_logo">
            <img src={logo} alt="Logo" />
          </div>
        </a>
        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
          <div className="navbar-nav">
            <Link className="nav-link active" to="/">Inicio <span className="sr-only">(current)</span></Link>
            <Link className="nav-link" to="/peluches">Peluches</Link>
            <Link className="nav-link" to="/tenis">Tenis</Link>
          </div>
        </div>
      </nav>
      <Routes>
        {/* <Route path="/" element={<Home />} /> */}
        <Route path="/" element={<Peluches />} />
        <Route path="/tenis" element={<Tenis />} />
        <Route path="/peluches" element={<Peluches />} />
        {/* Puedes agregar más rutas aquí */}
      </Routes>
    </Router>


  </>
);
}

export default App;
